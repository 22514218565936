/* eslint  react/forbid-prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import BlogPosts from '../components/BlogPosts';
import { headData } from '../data/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

const blogPage = ({ data }) => {
  const { title, lang } = headData;
  const {
    allMarkdownRemark: { nodes: blogPosts },
  } = data;

  const titleText = `ブログ一覧 | ${title}`;
  const description = `このサイトで扱っているテーマである音楽のほか、動画制作やYouTubeなどの動画制作関連の内容についても触れています。 | ${title}`;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{titleText}</title>
        <html lang={lang || 'en'} />
        <meta name="description" content={description} />
      </Helmet>
      <BlogPosts blogPosts={blogPosts} />
    </>
  );
};

export const pageQuery = graphql`
  {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, limit: 1000) {
      nodes {
        frontmatter {
          title
          slug
          category
          date(formatString: "YYYY/MM/DD")
        }
      }
    }
  }
`;

blogPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.array,
  }),
};

export default blogPage;
