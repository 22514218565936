import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import Title from '../Title/Title';

function BlogList({ blogPosts }) {
  const categories = Array.from(new Set(blogPosts.map(({ frontmatter }) => frontmatter.category)));

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title="カテゴリーから探す" />
          {categories.map((category) => (
            <a href={`/categories/${category}/`} key={category}>
              <h3 className="project-wrapper__text-title">{category}</h3>
            </a>
          ))}
          <Title title="ブログ一覧" />
          <div className="project-wrapper__text">
            {blogPosts.map(({ frontmatter: { title, slug, date } }) => (
              <a href={slug} key={slug}>
                <h3 className="project-wrapper__text-title">{title}</h3>
                <p style={{ color: '#aaa' }}>{`公開日: ${date}`}</p>
              </a>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
}

BlogList.propTypes = {
  blogPosts: PropTypes.node.isRequired,
};

export default BlogList;
