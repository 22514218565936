import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Hero2 from './Hero/Hero2';
import BlogList from './BlogList/BlogList';
import Footer from './Footer/Footer';

import { PortfolioProvider } from '../context/context';

import { heroData, footerData } from '../data/data';

function BlogPosts({ blogPosts }) {
  const [hero, setHero] = useState({});
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setHero({ ...heroData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ hero, footer }}>
      <Hero2 />
      <BlogList blogPosts={blogPosts} />
      <Footer />
    </PortfolioProvider>
  );
}

BlogPosts.propTypes = {
  blogPosts: PropTypes.node.isRequired,
};

export default BlogPosts;
